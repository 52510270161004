import { Box, GlobalStyles, Typography } from '@mui/material';
import { ContentType } from '@orgnc/core/components/organisms/Templates/helpers/dataOgAttributes';
import type { NextPage } from 'next';
import { useSiteSpecificComponents } from '@orgnc/core/hooks';
import { StaticFooter } from '../components/Footer';
import { getStatusCode } from '../utils/getStatusCode';

type ErrorProps = {
  statusCode?: number;
};

const CustomErrorPage: NextPage<ErrorProps> = ({ statusCode }) => {
  const { OnPageChangeLoadingIndicator, globalStyleSheet } =
    useSiteSpecificComponents();

  return (
    <OnPageChangeLoadingIndicator>
      <GlobalStyles styles={globalStyleSheet} />
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Box
          component="main"
          data-og-content-type={ContentType.pageNotFound}
          sx={{ flexGrow: '1', display: 'flex', justifyContent: 'center' }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              p: '100px 0',
              textAlign: 'center',
            }}
          >
            <Typography
              component="h1"
              sx={{
                fontSize: '120px',
                fontWeight: '700',
                lineHeight: 1,
                my: 1,
              }}
            >
              {statusCode}
            </Typography>

            <Box>
              <Typography
                component="h2"
                sx={{ fontWeight: 'bold', fontSize: 20 }}
              >
                Something Isn&apos;t Right.
              </Typography>
              <Typography
                component="h3"
                sx={{ fontWeight: 'bold', fontSize: 20 }}
              >
                THE LINK YOU FOLLOWED MAY BE BROKEN, OR THE PAGE MAY HAVE BEEN
                REMOVED.
              </Typography>
            </Box>
          </Box>
        </Box>
        <StaticFooter />
      </Box>
    </OnPageChangeLoadingIndicator>
  );
};

export default CustomErrorPage;

CustomErrorPage.getInitialProps = (ctx) => {
  const statusCode = getStatusCode(ctx);
  return { statusCode };
};
